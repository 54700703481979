import Vue from 'vue'
import VueRouter from 'vue-router'
import App from './App.vue'
import axios from 'axios'

Vue.config.productionTip = false

Vue.prototype.$http = axios

const routes = [
  { path: '/:bookingId', component: App },
]

const router = new VueRouter({
  routes
})

Vue.use(VueRouter)

new Vue({el: '#app', router, render: h => h(App) }).$mount('#app')
