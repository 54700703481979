<template>
  <div id="app">
    <h4 v-if="error">
      {{error}}
    </h4>

    <h4 v-if="booking.product_name" class="mb-3">
      Your {{displayedBookingType}} for "{{booking.product_name}}"
    </h4>

    <div v-if="booking.tickets">
      <div class="card text-center mx-auto mb-2" style="width: 18rem;" v-for="ticket in booking.tickets">
        <div class="card-body">
          <h5 class="card-title">{{ticket.tier_name}}</h5>
          <AddToAppleWalletButton :pass_url="ticket.pass_url"/>
        </div>
      </div>
    </div>
    <div v-else-if="booking.pass_url">
      <div class="card text-center mx-auto" style="width: 18rem;">
        <div class="card-body">
          <AddToAppleWalletButton :pass_url="booking.pass_url"/>
        </div>
      </div>
    </div>
  </div>
</template>


<script>

import AddToAppleWalletButton from './AddToAppleWalletButton.vue'

export default {
  name: 'AddToAppleWallet',
  components: {
    AddToAppleWalletButton
  },
  created () {
    if (this.$route.params.bookingId)
      this.getBooking()
    else
      this.error = "No tickets found."
  },
  data () {
    return {
      booking: {},
      error: null
    }
  },
  computed: {
    displayedBookingType () {
      if (this.booking.tickets)
        return 'tickets'
      else if (this.booking.voucher)
        return 'voucher'
      else
        return 'ticket'
    }
  },
  methods: {
    getBooking () {
      const baseURI = `${process.env.VUE_APP_ROOT_API}/wallets/apple/bookings/${this.$route.params.bookingId}.json`
      this.$http.get(baseURI)
      .then((result) => {
        this.booking = result.data
      }).catch( () => {
        this.error = "No tickets found."
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
