<template>
  <a :href="pass_url">
    <img src="https://developer.apple.com/wallet/images/add-to-apple-wallet.svg" alt="add to apple wallet"/>
  </a>
</template>


<script>

export default {
  name: 'AddToAppleWalletButton',
  props: ['pass_url']
}

</script>
